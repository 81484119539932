<template>
    <modal ref="modalAsignarTablero" titulo="Asignar entregas al operador logístico" tamano="modal-xl" no-aceptar adicional="Asignar" @adicional="asignar">
        <div class="row mx-0 a-center">
            <div class="col-auto px-2 text-general f-15 f-300">
                Día
            </div>
            <div class="col-3">
                <el-select v-model="fecha" placeholder="Select" size="small" @change="getConsultarPedidos">
                    <el-option
                    v-for="(fec,x) in fechas_entregas"
                    :key="x"
                    :label="fec | helper-fecha('dddd DD MMM YYYY')"
                    :value="fec"
                    />
                </el-select>
            </div>
        </div>
        <div class="row mx-0 mt-3">
            <p class="col-auto f-17 f-500 text-general mb-4">
                Entregas programadas para el: {{ fecha | helper-fecha('dddd DD MMMM YYYY') }}
            </p>
            <div class="col-3 mb-4 ml-auto">
                <el-input v-model="buscador" placeholder="Buscar" class="br-20 w-100 ml-auto" size="small" />
            </div>
            <div class="col-12">
                <el-table
                :data="pedidos.filter(data => !buscador || JSON.stringify(data).toLowerCase().includes(buscador.toLowerCase()))"
                header-row-class-name="text-general f-16 f-500"
                style="width: 100%"
                @selection-change="seleccionarPedido"
                >
                    <el-table-column
                    fixed
                    type="selection"
                    width="45"
                    prop="id"
                    value="id"
                    />
                    <el-table-column
                    fixed
                    class-name="text-center"
                    width="160"
                    >
                        <template slot-scope="scope">
                            <div class="row mx-0 justify-content-around">
                                <div class="col px-0">
                                    <el-tooltip v-if="scope.row.nuevo_intento > 1" placement="bottom" content="Nuevo intento de entrega" effect="light">
                                        <i class="icon-history f-20 text-general mx-2" />
                                    </el-tooltip>
                                </div>
                                <div class="col px-0">
                                    <el-tooltip v-if="scope.row.productos_faltantes" placement="bottom" content="Productos faltantes" effect="light">
                                        <i class="icon-package-variant f-20 text-general-red mx-2" />
                                    </el-tooltip>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                    fixed
                    label="Pedido"
                    width="100"
                    >
                        <template slot-scope="scope">
                            <p>
                                {{ scope.row.id }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Vendedor"
                    fixed
                    width="120"
                    class-name="text-center"
                    >
                        <template slot-scope="scope">
                            <p>
                                {{ scope.row.leechero }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="cliente"
                    label="Cliente"
                    class-name="text-center"
                    min-width="180"
                    />
                    <el-table-column
                    prop="cedi"
                    label="Cedis"
                    class-name="text-center"
                    min-width="180"
                    />
                    <el-table-column
                    label="Valor"
                    min-width="100"
                    class-name="text-center"
                    >
                        <template slot-scope="scope">
                            <p>
                                {{ separadorNumero(scope.row.valor_final_pedido) }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="entrega_horario"
                    label="Hora de Entrega"
                    class-name="text-center"
                    min-width="180"
                    :formatter="determinarHorario"
                    />
                    <el-table-column
                    prop="total_peso"
                    min-width="125"
                    label="Peso (Kg)"
                    class-name="text-center"
                    />
                    <el-table-column
                    prop="total_volumen"
                    min-width="140"
                    label="Volumen (m3)"
                    class-name="text-center"
                    />
                </el-table>
            </div>
        </div>
    </modal>
</template>

<script>
import Pedidos from "~/services/pedidos/pedidos-routing";
import Asignar from "~/services/pedidos/pedidos-admin-envios";
import moment from 'moment'
export default {
    data(){
        return {
            pedidos: [],
            id_pedidos: [],
            id_moneda: "",
            seleccionados: [],
            fechas_entregas: [],
            buscador: '',
            fecha: '',
        }
    },

    methods: {
        async getConsultarPedidos(){
            try {
                let params = {
                    id_pedidos: this.id_pedidos,
                    id_moneda: this.id_moneda,
                    fecha_entrega: this.fecha
                };
                const {data} = await Pedidos.getPedidosAsignarOperador(params)
                this.pedidos = data.pedidos;
            } catch (error){
                this.error_catch(error)
            }
        },

        seleccionarPedido(val){
            this.seleccionados = val;
        },

        async asignar(){
            if (this.seleccionados.length > 0){
                try {
                    let params =  {
                        ids: this.seleccionados,
                    }
                    const {data} = await Asignar.postAsignar(params)
                    this.notificacion('Mensaje', 'Asignación exitosa!', 'success')
                    this.$emit('actualizar');
                    this.$refs.modalAsignarTablero.toggle();
                } catch (error){
                    this.error_catch(error)
                }
            } else {
                this.notificacion('Atención', 'Debe seleccionar al menos un registro', 'warning')
            }
        },

        async toggle(id_pedidos,id_moneda,fechas){
            this.fechas_entregas = fechas;
            this.id_pedidos = id_pedidos;
            this.id_moneda = id_moneda;
            this.fecha = fechas[0];
            await this.getConsultarPedidos();
            this.$refs.modalAsignarTablero.toggle();
        },

        determinarHorario(row, column){
            if (row.entrega_horario < 12){
                return row.entrega_horario+":00 a.m";
            }
            if (row.entrega_horario >= 12){
                return row.entrega_horario+":00 p.m";
            }
            return "";
        },
    }
}
</script>
<style lang="css" scoped>
.text-green{
    color: #02A251;
}
</style>
